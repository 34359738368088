/* eslint-disable @typescript-eslint/no-unused-expressions */
import NextImage from 'components/NextImage';
import { NextLink } from 'components/NextLink';
import { ProductLabels, TProductLabels } from 'components/ProductLabels';
import { MouseEvent, useEffect, useState } from 'react';
import { COLORS } from 'styles/constants';
import type { ISocialMediaListComponents } from '../../../types/SocialSharing';
import { LiquidButton } from '../../LiquidButton';
import { ShareButton } from '../../ShareButton';
import socialMediaList from '../../SocialSharing/data';
import { TagList } from '../../TagList';
import { Text as Paragraph, Title } from '../../Typography';
import type { AnimationConfig, IAssetImage } from '../types';
import {
  BannerActions,
  BannerContentDetails,
  BoldString,
  BuyButtonWrapper,
  DetailsBranding,
  DetailsPanel,
  DetailsPanelContent,
  DetailsTitle,
  FillingQuantity,
  ProductPreparationContainer,
  ProductTagList,
  ProductTipContainer,
  SeeAllProductLabelsTitle,
} from './styles';

interface IProductDetailsProps {
  id: string;
  productName: string;
  productTags?: string[];
  productImage: IAssetImage;
  fillingQuantity?: string;
  productDescription?: string;
  buyNowLink?: string;
  labels?: Record<string, string>;
  displayTarget: 'mobile' | 'desktop';
  searchLink?: string;
  trackingEvents?: {
    clickSocialShare: (
      eventName: string,
      eventEC: string,
      socialPlatform: string,
      sharedItemName: string,
    ) => void;
    clickBuyNow?: (link: string) => void;
  };
  locale: string;
  productGtin: string;
  socialMediaListComponents?: ISocialMediaListComponents[];
  subBrandLogo?: {
    // add a name for alt ?
    url: string;
    height: number;
    width: number;
    mimeType: string;
  };
  certifications?: {
    name: string;
    url: string;
  }[];
  productLabels?: TProductLabels;
  seeAllProductLabelsTitle: string;
}

interface IProductPreparationProps {
  id: string;
  productName: string;
  preparationInstruction?: string;
  labels?: Record<string, string>;
  displayTarget: 'mobile' | 'desktop';
}

interface IProductTipProps {
  id: string;
  productName: string;
  cookingTip?: string[];
  labels?: Record<string, string>;
  displayTarget: 'mobile' | 'desktop';
}

export interface IProductHeroDetailsProps
  extends IProductDetailsProps,
    IProductPreparationProps,
    IProductTipProps {
  animationConfig: AnimationConfig;
  searchLink?: string;
  trackingEvents?: {
    clickSocialShare: (
      eventName: string,
      eventEC: string,
      socialPlatform: string,
      sharedItemName: string,
    ) => void;
    clickBuyNow?: (link: string) => void;
  };
  locale: string;
  productGtin: string;
  socialMediaListComponents?: ISocialMediaListComponents[];
  subBrandLogo?: {
    url: string;
    height: number;
    width: number;
    mimeType: string;
  };
}

const BuyButton = ({ text }: { text: string }) => (
  <BuyButtonWrapper>
    <LiquidButton
      text={text}
      height={52}
      width={200}
      color={COLORS.primary}
      textColor={COLORS.white}
    />
  </BuyButtonWrapper>
);

export const ProductDetails = ({
  id,
  productName,
  productTags = [],
  productImage,
  fillingQuantity = '',
  productDescription,
  buyNowLink,
  labels = {},
  displayTarget,
  searchLink = '',
  trackingEvents,
  socialMediaListComponents,
  locale,
  productGtin,
  subBrandLogo,
  seeAllProductLabelsTitle,
  productLabels,
}: IProductDetailsProps) => {
  const hasBuyNowButton = buyNowLink !== undefined && buyNowLink.length > 0;
  const hasBuyNowButtonFR = locale === 'fr_FR' && !!productGtin;
  const handleDataLayerPush = (link?: string) => {
    if (link) {
      trackingEvents?.clickBuyNow && trackingEvents.clickBuyNow(link.split(/[?#]/)[0]);
    }
  };

  const handleSeeAllCertificationsClick = (event: MouseEvent<HTMLAnchorElement>) => {
    const anchorElement = event.target as HTMLAnchorElement;
    const href = anchorElement.getAttribute('href') ?? '';
    const jumpmark = document.querySelector(href) as HTMLElement | null;

    if (!jumpmark) return;

    jumpmark.click();
  };

  return (
    <>
      <DetailsPanelContent data-test={`${id}`}>
        <DetailsBranding data-test={`${id}-mobile-branding`}>
          {subBrandLogo?.url && (
            <NextImage
              className="mx-[auto] max-w-[170px] relative md:mb-[20px] md:mx-[0]"
              src={subBrandLogo?.url}
              alt="SubBrand logo"
              height={subBrandLogo?.height}
              width={subBrandLogo?.width}
              mimeType={subBrandLogo?.mimeType}
            />
          )}
          <Title tag="h1" type="md" data-test={`${id}-mobile-branding-title`}>
            {productName}
          </Title>
        </DetailsBranding>
        {fillingQuantity && (
          <FillingQuantity tag="p" type="lead" data-test={`${id}-filling`}>
            {labels?.filling_quantity || 'Filling Quantity'}:{' '}
            <BoldString>{fillingQuantity}</BoldString>
          </FillingQuantity>
        )}
        {productDescription && (
          <Paragraph tag="p" type="md" data-test={`${id}-description`}>
            {productDescription}
          </Paragraph>
        )}
        <BannerActions>
          {hasBuyNowButton && !hasBuyNowButtonFR && (
            <NextLink
              href={buyNowLink}
              className="inline-block mr-2 mt-5"
              onClick={() => handleDataLayerPush(buyNowLink)}
              target="_blank"
              rel="noreferrer"
            >
              <span className="sr-only">{labels?.buy_now || 'Buy Now'}</span>
              <BuyButton text={labels?.buy_now || 'Buy Now'} />
            </NextLink>
          )}
          {hasBuyNowButtonFR && (
            <div data-widget-element data-widget-ean={productGtin}>
              <BuyButton text={labels?.buy_now || 'Buy Now'} />
            </div>
          )}

          <ShareButton
            eventEC="product"
            id={`${id}-share`}
            shareText={`${productName}`}
            socialMediaListComponents={
              socialMediaListComponents || (socialMediaList as ISocialMediaListComponents[])
            }
            shareImage={productImage.url}
            labels={labels}
            trackingEvents={
              trackingEvents?.clickSocialShare && {
                clickSocialShare: trackingEvents?.clickSocialShare,
              }
            }
          />
        </BannerActions>
        {productLabels && productLabels.length > 0 && (
          <>
            <ProductLabels labels={productLabels} isCentered />
            <SeeAllProductLabelsTitle
              href="#accordion-certifications"
              className="inline-block"
              onClick={handleSeeAllCertificationsClick}
            >
              {seeAllProductLabelsTitle}
            </SeeAllProductLabelsTitle>
          </>
        )}
        {productTags && (
          <ProductTagList>
            <TagList
              tags={productTags.filter((t) => t.trim().length > 0).map((t) => ({ label: t }))}
              centered={displayTarget === 'mobile'}
              data-test={`${id}-taglist`}
              searchLink={searchLink}
            />
          </ProductTagList>
        )}
      </DetailsPanelContent>
    </>
  );
};

export const ProductPreparation = ({
  id,
  preparationInstruction = '',
  labels = {},
}: IProductPreparationProps) => {
  const [prepInstructions, setPrepInstructions] = useState<string>();
  const [prepSteps, setPrepSteps] = useState<string[]>([]);

  useEffect(() => {
    const listVarOneRegex = /(\d)\)/;
    const listVarTwoRegex = /(\d)\./;
    let instructionParts;

    if (listVarOneRegex.test(preparationInstruction)) {
      instructionParts = preparationInstruction.split(listVarOneRegex);
    } else if (listVarTwoRegex.test(preparationInstruction)) {
      instructionParts = preparationInstruction.split(listVarTwoRegex);
    } else {
      setPrepInstructions(preparationInstruction);
      return;
    }

    // Check for non-numbered instruction overview
    if (instructionParts.length <= 1) setPrepInstructions(preparationInstruction);
    if (!/^\d$/.test(instructionParts[0].trim())) {
      setPrepInstructions(instructionParts[0].trim());
      instructionParts = instructionParts.slice(1, instructionParts.length);
    }

    const parsedSteps: string[] = instructionParts.reduce((steps, curr) => {
      if (curr.trim().length < 1 || /^\d$/.test(curr.trim())) return steps;
      return [...steps, curr.trim()];
    }, [] as string[]);

    setPrepSteps(parsedSteps);
  }, [preparationInstruction]);

  return (
    <ProductPreparationContainer>
      <DetailsPanelContent $mobilePadding data-test={`${id}`}>
        <DetailsTitle tag="h3" type="md" data-test={`${id}-title`}>
          {labels?.preparation}
        </DetailsTitle>
        {prepInstructions && (
          <Paragraph tag="p" type="lg" data-test={`${id}-intro`} className="md:mb-5">
            {prepInstructions}
          </Paragraph>
        )}
        {prepSteps.length > 0 && (
          <ol className="list-decimal list-inside">
            {prepSteps.map((step, idx) => (
              <Paragraph
                tag="li"
                type="md"
                data-test={`${id}-steps-${idx}`}
                className="list-item md:mb-5 py-6 md:py-0 border-b border-light-divider last:border-0 md:border-0"
                key={step}
              >
                {step}
              </Paragraph>
            ))}
          </ol>
        )}
      </DetailsPanelContent>
    </ProductPreparationContainer>
  );
};

export const ProductTip = ({ id, cookingTip = [''], labels = {} }: IProductTipProps) => (
  <ProductTipContainer>
    <Paragraph tag="h4" type="lead" className="mb-1.5 md:mb-2.5 text-darkgray">
      {labels?.quick_tips}
    </Paragraph>
    <DetailsTitle tag="h3" type="md" data-test={`${id}-title`}>
      {labels?.cooking_tip}
    </DetailsTitle>
    {cookingTip.map((tip: string) => (
      <Paragraph
        tag="p"
        type="lg"
        data-test={`${id}-text`}
        className="mb-5 text-center md:text-left"
        key={tip}
      >
        {tip}
      </Paragraph>
    ))}
  </ProductTipContainer>
);

export const ProductHeroDetails = ({
  id,
  productName,
  productTags = [],
  productImage,
  fillingQuantity,
  productDescription = '',
  buyNowLink = undefined,
  labels = {},
  animationConfig,
  searchLink = '',
  trackingEvents,
  locale,
  productGtin,
  socialMediaListComponents,
  subBrandLogo,
  productLabels,
  seeAllProductLabelsTitle,
}: IProductHeroDetailsProps) => (
  <>
    <DetailsPanel>
      <BannerContentDetails>
        <ProductDetails
          id={`${id}-info`}
          productName={productName}
          productTags={productTags}
          productImage={productImage}
          productLabels={productLabels}
          fillingQuantity={fillingQuantity}
          productDescription={productDescription}
          buyNowLink={buyNowLink}
          labels={labels}
          displayTarget={animationConfig?.target || 'desktop'}
          searchLink={searchLink}
          trackingEvents={
            trackingEvents?.clickSocialShare && {
              clickSocialShare: trackingEvents?.clickSocialShare,
              clickBuyNow: trackingEvents?.clickBuyNow,
            }
          }
          locale={locale}
          productGtin={productGtin}
          socialMediaListComponents={socialMediaListComponents}
          subBrandLogo={subBrandLogo}
          seeAllProductLabelsTitle={seeAllProductLabelsTitle}
        />
      </BannerContentDetails>
    </DetailsPanel>
  </>
);
